import React, { useState, useContext } from 'react';
import {  Text, View, TouchableHighlight } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { useFonts, AlfaSlabOne_400Regular } from '@expo-google-fonts/alfa-slab-one';
import { OpenSans_400Regular, OpenSans_700Bold } from '@expo-google-fonts/open-sans';
import { AppLoading } from 'expo';
import { UserContext } from './UserContext';
import Api from '../api/api';
import AuthApi from '../api/authentication';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Cookies from 'js-cookie';
import { LoadingContext } from './LoadingContext';

function Header(props){
    let [fontsLoaded] = useFonts({
        AlfaSlabOne_400Regular,
        OpenSans_700Bold
    });

    const {value, setValue} = useContext(UserContext)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    // if (!fontsLoaded) {
    //     return <AppLoading />;
    // }

    async function logout(){
        setGlobalLoading(true)
        const resp = await AuthApi.logout().catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            delete Api.defaults.headers.common['Authorization']
            Cookies.remove('jwt')
            setValue({user:null, loggedIn:false})
        }
        setGlobalLoading(false)
    }

    return(
        <View style={{backgroundColor:'#fff', paddingLeft:20, paddingRight:20, borderBottomWidth:'thin', 
        borderBottomColor:'#d0d0d0', position:'fixed', width:'100%', zIndex:2}}>
            <View style={{height:57, justifyContent:'center', alignItems:'center'}}>
                <View style={{
                    width:'100%', 
                    position:'absolute', 
                    top:0, 
                    left:0, 
                    zIndex:2, 
                    height:57, 
                    display:'flex', 
                    flexDirection:'row',
                    justifyContent:'space-between', 
                    alignItems:'center'}}>
                    {props.changeAccount && (
                        <TouchableHighlight onPress={props.changeAccount}>
                            <View>
                                <Text style={{fontFamily:'OpenSans_700Bold'}}>Change</Text>
                            </View>
                        </TouchableHighlight>
                    )}
                    
                    {props.back !== false && (
                        <TouchableHighlight style={{onPress:'tran'}} onPress={() => {
                            if(props.navigation.canGoBack()){
                                props.navigation.goBack()
                            }else{
                                props.navigation.navigate('Home')
                            }
                        }}>
                            <View>
                                <Text>Go Back</Text>
                            </View>
                        </TouchableHighlight>
                    )}
                    
                    {props.logout && (
                        <TouchableHighlight onPress={logout}>
                            <View>
                                <Text style={{fontFamily:'OpenSans_700Bold'}}>Logout</Text>
                            </View>
                        </TouchableHighlight>
                    )}
                    
                </View>
                <View style={{position:'absolute', width:'100%', textAlign:'center'}}>
                    <Text style={{fontFamily:'AlfaSlabOne_400Regular', fontSize:16, color:'#f7a81b'}}>{props.title}</Text>
                </View>
            </View>
            {props.children}
        </View>
    )
}

export default Header