import React, {useEffect, useState, useContext} from 'react';
import { StyleSheet, Text, View, TouchableHighlight, ScrollView } from 'react-native';
import AuthApi from '../api/authentication';
import Api from "../api/api";
import { UserContext } from '../components/UserContext';
import { LoadingContext } from '../components/LoadingContext';
import Cookie from 'js-cookie';

function ChangeAccount({props:navigation, close}){

    const {value, setValue} = useContext(UserContext)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    const [accounts, setAccounts] = useState([])

    async function selectAccount(id) {
        setGlobalLoading(true)
        const resp = await AuthApi.selectAccount(id).catch((error) => {
            console.error(error);
        })

        if(resp){
            Cookie.set('jwt', resp.data.token)
            Api.defaults.headers.common['Authorization'] = `Bearer ${resp.data.token}`;
            setValue({...value, user:resp.data.user})
            close(true)
        }
        
        setGlobalLoading(false)
    }

    async function getAccounts() {
        setGlobalLoading(true)
        const resp = await AuthApi.accounts().catch((error) => {
            console.error(error);
        })
        if(resp){
            const data = resp.data.map(d => {
                
                d.selected = false
                if(d.nickname == value?.user?.nickname){
                    d.selected = true
                }

                return d
            })
            // console.log(data)
            setAccounts(data)
        }
        setGlobalLoading(false)
    }

    useEffect(() => {
        console.log(value)
        getAccounts()
    }, [])

    return(
        <View  style={styles.container}>
            <View style={{backgroundColor:'#fff', height:57, justifyContent:'center', paddingLeft:20}}>
                <TouchableHighlight onPress={() => close()}>
                    <View><Text>Close</Text></View>
                </TouchableHighlight>
            </View>
            <View style={{justifyContent:'center', alignItems:'center', backgroundColor:'white', paddingBottom:50}}>
                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:18}}>Select account</Text>
            </View>
            <ScrollView contentContainerStyle={{flexGrow: 1, backgroundColor:'white'}}> 
                {accounts.map(account => (
                    <TouchableHighlight key={account.id} onPress={() => selectAccount(account.id)} style={{height:70, margin:10, justifyContent:'center', alignItems:'center', border:'1px solid'}}>
                        <View>
                            <Text style={{fontFamily:'OpenSans_700Bold', fontSize:18}}>{account.nickname}</Text>
                            {account.selected && (
                                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:14, color:'green'}}>selected</Text>
                            )}
                        </View>
                    </TouchableHighlight>
                ))}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
      position:'fixed',
      top:0,
      left:0,
      width:'100%',
      height:'100%',
      zIndex:999
    },
});

export default ChangeAccount