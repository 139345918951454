import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, Text, View, TextInput, TouchableHighlight } from 'react-native';
import { AppLoading } from 'expo';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLinkTo } from '@react-navigation/native';
import AuthApi from "../api/authentication";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { LoadingContext } from '../components/LoadingContext';

export default function SetNewPassword(props) {
    const [loading, setLoading] = useState(true)
    const [token, setToken] = useState(null)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    
    const linkTo = useLinkTo();
    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold,
        OpenSans_800ExtraBold
    });

    async function checkTokenValidity(_token) {
        setGlobalLoading(true)
        const resp = await AuthApi.checkResetPasswordToken(_token).catch(() => {})
        if(resp){
            setToken(_token)
        }
        setGlobalLoading(false)
        setLoading(false)
    }

    async function newPassword(values) {
        setGlobalLoading(true)
        const obj = {...values, token}
        const resp = await AuthApi.setNewPassword(obj).catch(() => {})

        if(resp){
            toastr.success(resp.data.message)
            linkTo('/')
        }
        setGlobalLoading(false)
    }

    useEffect(() => {
        checkTokenValidity(props.route.params.token)
    }, [])

    if (!fontsLoaded) {
        return <AppLoading />;
    }

    return(
            <View style={[styles.container, {marginLeft:20, marginRight:20}]}><Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:40, textAlign:'center'}}>Set new password</Text>
            
            <Formik
                initialValues={{ 
                    password_confirmation:'', password:'' 
                }}
                onSubmit={(values, actions) => {
                    newPassword(values)
                }}
                
                validationSchema={validationSchema}
                >
                {({values, submitForm, setFieldValue, isValid, dirty, errors}) => (

                <View style={{maxWidth:'80%', width:'100%', marginTop:100}}>                    
                    {!loading && token && (
                        <React.Fragment>
                            <View style={textStyles.container}>
                                <TextInput
                                    style={textStyles.field}
                                    placeholder={'New Password'}
                                    secureTextEntry={true}
                                    onChangeText={text => setFieldValue('password', text)}
                                    value={values.password}
                                />
                                <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.password}</Text>
                            </View>
                            
                            <View style={textStyles.container}>
                                <TextInput
                                    style={textStyles.field}
                                    placeholder={'Repeat new password'}
                                    secureTextEntry={true}
                                    onChangeText={text => setFieldValue('password_confirmation', text)}
                                    value={values.password_confirmation}
                                />
                                <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.password_confirmation}</Text>
                            </View>

                            <TouchableHighlight disabled={!(isValid && dirty)} onPress={() => submitForm()}>
                                <View style={[buttonStyle.container, {opacity:!(isValid && dirty) ? '0.5' : 1}]}>
                                    <Text style={buttonStyle.text}>Set New Password</Text>
                                </View>
                            </TouchableHighlight>
                        </React.Fragment>
                    )}

                    {!loading && !token && (
                        <View>
                            <Text style={{textAlign:'center', marginBottom:100, fontFamily:'OpenSans_700Bold', fontSize:25}}>Token not valid</Text>
                        </View>
                    )}

                    <TouchableHighlight style={{marginTop:20}} onPress={() => linkTo('/')}>
                        <View style={buttonStyle.container}>
                            <Text style={buttonStyle.text}>Return to Home</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )}
            </Formik>

        </View>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
  
  const activity = StyleSheet.create({
    container: {
      margin: 'auto',
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      zIndex:9999
    },
  });
  
  const textStyles = StyleSheet.create({
    container: {
      marginBottom:30,
      position:'relative'
    },
    field: {
      height: 50, 
      borderColor: '#0f1c2d', 
      color:'#0f1c2d',
      borderWidth: 2, 
      width:'100%', 
      padding:5, 
      outlineColor: '#f7a81b',
      fontFamily: 'OpenSans_400Regular',
    }
  });
  
  const buttonStyle = StyleSheet.create({
      container:{
          width:'100%',
          height:50,
          backgroundColor:'#0f1c2d',
          justifyContent:'center',
          textAlign:'center',
          color:'white',
          alignContent:'center',
          fontFamily:'OpenSans_700Bold'
      },
      text: {
          color:'white',
          fontFamily:'OpenSans_700Bold',
          textTransform:'uppercase'
      }
  })
  
  const passwordRecovery = StyleSheet.create({
      container: {
          marginTop:30,
          marginBottom:30,
          width:'100%',
          justifyContent:'center',
          textAlign:'center',
          alignContent:'center',
      },
      text: {
          color:'#0f1c2d',
          fontFamily:'OpenSans_700Bold',
          textTransform:'uppercase'
      }
  })
  
  const validationSchema = Yup.object().shape({
    password: Yup.string()
        .label('Password')
        .required()
        .min(8),
    password_confirmation: Yup.string()
        .label('Repeat Password')
        .required()
        .min(8)
        .oneOf([Yup.ref('password'), ""], 'Password must be the same.'),
    })