import React, { useEffect, useState, useContext } from 'react';
import {Text, View, TouchableHighlight, StyleSheet } from 'react-native';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { AppLoading } from 'expo';
import UserApi from '../api/user';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { LoadingContext } from '../components/LoadingContext';

function QrUrlReader(props) {
    const [task, setTask] = useState({})
    const [qrCode, setQrCode] = useState(null)
    const [loading, setLoading] = useState(true)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)

    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold,
        OpenSans_800ExtraBold
    });

    async function checkQrCode(qrCode){
        setGlobalLoading(true)
        const resp = await UserApi.check_qr_code(qrCode).catch(() => {})
        if(resp){
            setTask(resp.data.data)
            setQrCode(qrCode)
        }
        setGlobalLoading(false)
        setLoading(false)
    }

    async function saveTask(){
        setGlobalLoading(true)
        const obj = {qr_code:qrCode}
        const resp = await UserApi.new_task_data(obj).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            props.navigation.navigate('Dashboard')
        }
        setGlobalLoading(false)
    }

    useEffect(() => {
        checkQrCode(props.route.params.qr_code)
    },[])

    if (!fontsLoaded) {
        return <AppLoading />;
    }

    return(
        <View style={{flex:1, backgroundColor: '#fff',alignItems: 'center',justifyContent: 'center', }}>
            {!loading && !task.id && (
                <View style={{maxWidth:'80%', width:'100%'}}>
                    <Text style={{textAlign:'center', marginBottom:100, fontFamily:'OpenSans_700Bold', fontSize:25}}>Qr code not valid</Text>
                    <TouchableHighlight onPress={() => props.navigation.navigate('Dashboard')}>
                        <View style={[buttonStyle.container]}>
                            <Text style={buttonStyle.text}>Return to Home</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )}

            {!loading && task.id && (
                <React.Fragment>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:40}}>Task</Text>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:30}}>{task.name}</Text>


                    <View style={{maxWidth:'80%', width:'100%', marginTop:30}}>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginBottom:30}}>
                            <View style={{textAlign:'center'}}>
                                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:25}}>Entries</Text>
                                <Text style={{fontSize:25}}>{task.score}</Text>
                            </View>
                            <View style={{textAlign:'center'}}>
                                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:25}}>Tickets</Text>
                                <Text style={{fontSize:25}}>{task.tickets}</Text>
                            </View>
                        </View>


                        <TouchableHighlight onPress={() => saveTask()}>
                            <View style={[buttonStyle.container]}>
                                <Text style={buttonStyle.text}>Save task</Text>
                            </View>
                        </TouchableHighlight>
                        
                        <TouchableHighlight style={{marginTop:20}} onPress={() => props.navigation.navigate('Dashboard')}>
                            <View style={[buttonStyle.container]}>
                                <Text style={buttonStyle.text}>Return to Home</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                </React.Fragment>
            )}
            
        </View>
    )
}

const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold'
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

export default QrUrlReader
