import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Image, Platform} from 'react-native';

function AddToHomeScreen({os}){
    const [addToHome, setAddToHome] = useState(false)

    useEffect(() => {
        if(Platform.OS == 'ios' || Platform.OS == 'android'){
            const firstOpen = localStorage.getItem('rotary_f_open')
            if(!firstOpen){
                setAddToHome(true)
                localStorage.setItem('rotary_f_open', true)
            }
        }
    })
    
    return (
        <React.Fragment>
            {Platform.OS == 'ios' && addToHome && (
                <View style={styles.container}>
                    <Text style={styles.text}>Install this webapp on your iPhone: tap <Image style={{ width: 20, height: 20, tintColor:'white' }} source={require('../assets/upload.png')} /> and then Add to homescreen.</Text>
                </View>
            )}
            
            {Platform.OS == 'android' && addToHome && (
                <View style={styles.container}>
                    <Text style={styles.text}>Install this webapp on your Android: tap <Image style={{ width: 20, height: 20, tintColor:'white' }} source={require('../assets/menu.png')} /> and then Add to homescreen.</Text>
                </View>
            )}
        </React.Fragment>
    )
}

const styles = StyleSheet.create({
    container: {
        bottom: 20,
        // marginLeft:15,
        
        // marginRight: 15,
       
        position:'fixed',
        flexDirection:'row',
        width:'100%',
        justifyContent:'center'        
        
    },
    text: {
        color:'white',
        backgroundColor:'#f99d1e',
        borderRadius:'4px',
        padding:7,
        alignContent:'center',
        display:'flex'
    }
  });

export default AddToHomeScreen