import 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Home from './screens/Home';
import SignUp from './screens/SignUp';
import PasswordRecovery from './screens/PasswordRecovery';
import NicknameRecovery from './screens/NicknameRecovery';
import Dashboard from './screens/Dashboard';
import QrUrlReader from './screens/QrUrlReader';
import SetNewPassword from './screens/SetNewPassword';
import { useFonts, AlfaSlabOne_400Regular } from '@expo-google-fonts/alfa-slab-one';
import { Text, View, ActivityIndicator} from 'react-native';
import './assets/style.css';
import { UserContext } from './components/UserContext';
import {LoadingContext}  from './components/LoadingContext';
import Cookie from 'js-cookie';
import AuthApi from './api/authentication';
import UserApi from './api/user';
import Api from './api/api';
import AddToHomeScreen from './components/AddToHomeScreen';

const Stack = createStackNavigator();

const linking = {
  // prefixes: ['http://localhost:19006'],
  config: {
    screens: {
      Home: '',
      SignUp: '/sign-up',
      PasswordRecovery: '/password-recovery',
      Dashboard: '/dashboard',
      QrUrlReader: '/qr-url-reader/:qr_code',
      SetNewPassword: '/reset-password/:token',
    }
  }
};

function App() {
  let [fontsLoaded] = useFonts({
    AlfaSlabOne_400Regular,
  });

  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState({ user: null, loggedIn: false, loading:false })
  const [globalLoading, setGlobalLoading] = useState(false)

  async function getMe(jwt){
    Api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    const resp = await AuthApi.me().catch(() => {})
    if(resp){
      setValue({ user: resp.data.data, loggedIn: true })
    }

    setLoading(false)

  }


  {/*
  
  <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon.png">
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  <link rel="manifest" href="/site.webmanifest">
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5">
  <meta name="msapplication-TileColor" content="#da532c">
  <meta name="theme-color" content="#ffffff">
  
  */}

  async function getVersion(){
    const resp = await UserApi.get_version().catch(() => {})
  }

  useEffect(() => {
    getVersion()
    const jwt =  Cookie.get('jwt')
    if(jwt){
      getMe(jwt)
    }else{
      setLoading(false)
    }

  }, [])

  if(loading){
    return (
      <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
        <Text style={{fontFamily:'AlfaSlabOne_400Regular', fontSize:20}}>Loading...</Text>
      </View>);
  }

  return (
    <UserContext.Provider value={{value, setValue}}>
      <LoadingContext.Provider value={{globalLoading, setGlobalLoading}}>
        {globalLoading && (
          <View style={{position:'fixed',zIndex:9999,top:0,left:0,width:'100%',height:'100%', flex:1, justifyContent:'center', alignItems:'center'}}>
            <View style={{padding:10, backgroundColor:'black', opacity:0.6, padding:10, borderRadius:5}}>
              <ActivityIndicator color="#fff" size="large" />
              <Text style={{color:'white', marginTop:10}}>Loading...</Text>
            </View>
          </View>
        )}
      
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={{headerShown: false}}>
            {value.loggedIn && (
              <React.Fragment>
                <Stack.Screen name="Dashboard" component={Dashboard} />
                <Stack.Screen name="QrUrlReader" component={QrUrlReader} />
              </React.Fragment>
            )}

            {!value.loggedIn && (
              <React.Fragment>
                <Stack.Screen name="Home" component={Home} />
                <Stack.Screen name="SignUp" component={SignUp} />
                <Stack.Screen name="PasswordRecovery" component={PasswordRecovery} />
                <Stack.Screen name="NicknameRecovery" component={NicknameRecovery} />
                <Stack.Screen name="SetNewPassword" component={SetNewPassword} />
              </React.Fragment>
            )}
            
          </Stack.Navigator>
        </NavigationContainer>
        
        <AddToHomeScreen />
      </LoadingContext.Provider>
    </UserContext.Provider>
  );
}

export default App


