import React, {useEffect, useState, useContext} from 'react';
import { StyleSheet, Text, View, TouchableHighlight, FlatList, Image } from 'react-native';
import Header from '../components/Header';
import { AppLoading } from 'expo';
import QrReader from './QrReader';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold } from '@expo-google-fonts/open-sans';
import UserTaskApi from '../api/user_task';
import moment from 'moment';
import ChangeAccount from './ChangeAccount';
import { UserContext } from '../components/UserContext';

const dinamic_log = true;

const Item = ({ created_at, task, score, tickets }) => (
    <View style={styles.item}>
      <Text style={{fontFamily:'OpenSans_700Bold'}}>D/T: {moment(created_at).isValid() ? moment(created_at).format('YYYY-MM-DD HH:mm') : ''}</Text>
      <Text style={{paddingTop:10, paddingBottom:10, fontSize:18, fontFamily:'OpenSans_700Bold'}}>Task: {task.name}</Text>
      <View style={{flexDirection:'row'}}>
        {/*<Text style={{}}>Minutes: {score}</Text>*/}
        <Text >Tickets: {tickets}</Text>
      </View>
    </View>
  );

const DATA = [
    {
      id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
      created_at:'2020-10-01 10:29',
      task:'ABC123',
      score:10,
      tickets: 10
    },
  ];

export default function Dashboard(props){
    const [qrReaderVisible, setQrReaderVisible] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState({tasks:0, scores:0, tickets:0})
    const [version, setVersion] = useState('')
    const [changeAccount, setChangeAccount] = useState(false)
    const {value} = useContext(UserContext)

    async function getData() {
        setData([])
        const resp = await UserTaskApi.index().catch(() => {})
        if(resp){
            setData(resp.data.data)
            calcolateTotals(resp.data.data)
        }
    }

    function calcolateTotals(_data){
        let _total = {tasks:0, scores:0, tickets:0}

        _data.forEach(_task => {
            _total.tasks += 1
            _total.scores += _task.score
            _total.tickets += _task.tickets
        });
        setTotal(_total)
    }

    useEffect(() => {
        getData()
        const _version = localStorage.getItem('rotary_v')
        setVersion(_version)
        console.log(value);
    }, []);

    function openQrReader(){
        setQrReaderVisible(true)
    }

    function closeQrReader(){
        setQrReaderVisible(false)
    }

    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold
    });

    const renderItem = ({ item }) => <Item created_at={item.created_at} task={item.task} score={item.score} tickets={item.tickets} />;

    if (!fontsLoaded) {
        return <AppLoading />;
    }

    return(
        <React.Fragment>
            {qrReaderVisible && (
                <QrReader closeQrReader={closeQrReader} getData={getData} />
            )}

            {changeAccount && (
                <ChangeAccount close={(status) => {
                    setChangeAccount(false)
                    if(status){
                        getData()
                    }
                }} />
            )}


            <Header {...props} title="Real time results" back={false} logout={true} changeAccount={() => setChangeAccount(true)}>
                <React.Fragment>
                    <View style={{margin:'auto', paddingBottom:10}}>
                        <Image style={{ textAlign:'center', width:250, height:100 }} source={dinamic_log? {uri:'https://api.westonrotaryrun.com/images/logo.png'}: require('../assets/logo-punteggi.jpg')}/>
                    </View>
                    <View style={{alignItems:'center', marginBottom:10}}>
                        <Text style={{fontWeight:'bold', fontSize:12}}>{value?.user?.nickname}</Text>
                        <Text style={{fontWeight:'bold', fontSize:12}}>{value?.user?.team?.name ?? '-'}</Text>
                    </View>
                    <View style={{height:80, flexDirection:'row', justifyContent:'space-around'}}>
                        {/* <View style={{textAlign:'center', width:'50%'}}>
                            <Text style={{fontFamily:'OpenSans_700Bold', fontSize:13}}>Get Fit Challenge Minutes</Text>
                            <Text style={{fontSize:40}}>{total.scores}</Text>
                        </View> */}
                        {/* <View style={{textAlign:'center'}}>
                            <Text style={{fontFamily:'OpenSans_700Bold'}}>Entries</Text>
                            <Text style={{fontSize:40, color:total.scores <= 10 ? 'red' : '#0f1c2d'}}>{total.scores}</Text>
                        </View> */}
                        <View style={{textAlign:'center', width:'50%'}}>
                            <Text style={{fontFamily:'OpenSans_700Bold'}}>Raffle Tickets</Text>
                            <Text style={{fontSize:40}}>{total.tickets}</Text>
                        </View>
                    </View>
                    {total.scores <= 10 && (
                        <Text style={{fontSize:9, paddingBottom:5, fontWeight:'bold', textAlign:'center', color:'red'}}>Commission Cup qualification requires the mile walk at the event</Text>
                    )}
                </React.Fragment>
            </Header>
            <View style={{flex:1, marginTop:320, marginBottom:150}}>
                {data.length == 0 && (
                    <Text style={{textAlign:'center', fontFamily:'OpenSans_700Bold'}}>No Data</Text>
                )}
                <FlatList data={data} renderItem={renderItem} keyExtractor={item => `${item.id}`} />
            </View>
            <View style={{position:'fixed', zIndex:2, bottom:0, left:0, width:'100%', 
            padding:20, backgroundColor:'white', boxShadow:'0px 20px 45px 0px rgba(0,0,0,0.48)'}}>
                <TouchableHighlight onPress={() => openQrReader()}>
                    <View style={buttonStyle.container}>
                        <Text style={buttonStyle.text}>Scan new QR code</Text>
                    </View>
                </TouchableHighlight>
                {version && version != '' && (
                    <Text style={{fontSize:11, fontWeight:'bold'}}>version:{version}</Text>
                )}
            </View>
        </React.Fragment>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 0,
    },
    item: {
      backgroundColor: 'white',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      fontFamily:'OpenSans_400Regular'
    },
    title: {
      fontSize: 32,
    },
  });

  const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold'
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})