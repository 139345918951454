import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, Text, View, TextInput, TouchableHighlight, Dimensions } from 'react-native';
import { AppLoading } from 'expo';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import QrReaderScan from 'react-qr-reader'
import UserApi from '../api/user';
import * as Yup from 'yup';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { LoadingContext } from '../components/LoadingContext';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

function QrReader(props){
    const [start, setStart] = useState('wait')
    const [qrError, setQrError] = useState('')
    const [task, setTask] = useState({})
    const [qrCode, setQrCode] = useState(null)
    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    

    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold,
        OpenSans_800ExtraBold
    });

    useEffect(() => {
        setStart('start')
    }, [])

    const previewStyle = {
        height: '100%',
        width: '100%',
    }

    function handleError(err){
        setQrError('Error in opening the camera, make sure you have given permissions.')
        setStart('error')
    }

    async function checkQrCode(qrCode){
        const resp = await UserApi.check_qr_code(qrCode).catch(() => {
            setStart('start')
        })

        if(resp){
            setQrCode(qrCode)
            setTask(resp.data.data)
            setStart('form')
        }
    }
      
    function handleScan(data){
        if(data !== null){
            setStart('wait')
            let url = data.split('/')
            checkQrCode(url[url.length -1])
        }
    }

    async function saveTask(){
        setGlobalLoading(true)
        const obj = {qr_code:qrCode}
        const resp = await UserApi.new_task_data(obj).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            props.closeQrReader()
            props.getData()
        }
        setGlobalLoading(false)
    }

    if (!fontsLoaded) {
        return <AppLoading />;
    }
    

    return(
        <View style={styles.container}>
            <View style={{backgroundColor:'#fff', height:57, justifyContent:'center', paddingLeft:20}}>
                <TouchableHighlight onPress={() => props.closeQrReader()}>
                    <View><Text>Close</Text></View>
                </TouchableHighlight>
            </View>
            {start !== 'form' && (
                <View style={{flex:1,alignItems: 'center',justifyContent: 'center'}}>
                    {start == 'wait' && (
                        <Text style={{fontSize:30, color:'white', fontWeight:'bold'}}>Wait...</Text>
                    )}
                    {start == 'error' && qrError && (
                        <Text style={{fontSize:20, color:'white', fontWeight:'bold', padding:20}}>{qrError}</Text>
                    )}

                    {start == 'start' && (
                        <QrReaderScan
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                        />
                    )}
                    
                </View>
            )}
            
            {start == 'form' && (
                <View style={{flex:1, backgroundColor: '#fff',alignItems: 'center',justifyContent: 'center', }}>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:40}}>Task</Text>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:30}}>{task.name}</Text>


                    <View style={{maxWidth:'80%', width:'100%', marginTop:30}}>
                        <View style={{flexDirection:'row', justifyContent:'space-evenly', marginBottom:30}}>
                            <View style={{textAlign:'center'}}>
                                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:25}}>Entries</Text>
                                <Text style={{fontSize:25}}>{task.score}</Text>
                            </View>
                            <View style={{textAlign:'center'}}>
                                <Text style={{fontFamily:'OpenSans_700Bold', fontSize:25}}>Tickets</Text>
                                <Text style={{fontSize:25}}>{task.tickets}</Text>
                            </View>
                        </View>


                        <TouchableHighlight onPress={() => saveTask()}>
                            <View style={[buttonStyle.container]}>
                                <Text style={buttonStyle.text}>Save task</Text>
                            </View>
                        </TouchableHighlight>
                        
                        <TouchableHighlight style={{marginTop:20}} onPress={() => {setStart('start'); setQrCode(null)}}>
                            <View style={buttonStyle.container}>
                                <Text style={buttonStyle.text}>Rescan QrCode</Text>
                            </View>
                        </TouchableHighlight>
                    </View>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
      position:'fixed',
      top:0,
      left:0,
      width:'100%',
      height:'100%',
      zIndex:999
    },
  });
  
  const textStyles = StyleSheet.create({
    container: {
      marginBottom:30,
      position:'relative'
    },
    field: {
      height: 50, 
      borderColor: '#0f1c2d', 
      color:'#0f1c2d',
      borderWidth: 2, 
      width:'100%', 
      padding:5, 
      outlineColor: '#f7a81b',
      fontFamily: 'OpenSans_400Regular',
    }
  });
  
  const buttonStyle = StyleSheet.create({
      container:{
          width:'100%',
          height:50,
          backgroundColor:'#0f1c2d',
          justifyContent:'center',
          textAlign:'center',
          color:'white',
          alignContent:'center',
          fontFamily:'OpenSans_700Bold'
      },
      text: {
          color:'white',
          fontFamily:'OpenSans_700Bold',
          textTransform:'uppercase'
      }
  })
  
  const validationSchema = Yup.object().shape({
      score: Yup.string()
        .label('Score')
        .required(),
      tickets: Yup.string()
        .label('Tickets')
        .required(),
    })

export default QrReader