import api from "./api";
import utility from './utility'

export default {
    index:(search) => {
        var _search = utility.appendParameters(search); 
        return api.get(`v1/teams?${_search}`)
    },
    store:(obj) => {
        return api.post(`v1/teams`, obj)
    },
}