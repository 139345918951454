import React, { useEffect, useState, useContext } from 'react'
import { LoadingContext } from '../components/LoadingContext';
import {  Text, View, TouchableHighlight, TextInput, StyleSheet, FlatList } from 'react-native';
import TeamApi from '../api/team';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const Item = ({ name, selectGroup, group }) => (
    <TouchableHighlight onPress={() => selectGroup(group)}>
        <View style={styles.item}>
            <Text style={{fontFamily:'OpenSans_700Bold'}}>{name}</Text>
        </View>
    </TouchableHighlight>
  );


export default function SearchGroup(props){

    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)
    const [typing, setTyping] = useState({ typing: false, typingTimeout: 0})
    const [searchText, setSearchText] = useState('')
    const [data, setData] = useState([])

    useEffect(() => {
        var body = document.getElementsByTagName("BODY")[0]
        body.classList.add("fixed");
    },[])

    async function getData(text){
        setGlobalLoading(true)
        const resp = await TeamApi.index({search:text}).catch(() => {})
        if(resp){
            setData(resp.data.data)
        }
        setGlobalLoading(false)
    }

    async function searchServerGroup(text){
        const value = text
        if(typing.typingTimeout){
            clearTimeout(typing.typingTimeout)
        }

        setTyping({
            typing: false,
            typingTimeout: setTimeout(() => {
                getData(value)
                setSearchText(value)
            }, 500)   
        })
    }

    async function createGroup(){
        setGlobalLoading(true)
        const resp = await TeamApi.store({name:searchText}).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            getData(searchText)
        }else{
            setGlobalLoading(false)
        }
    }

    function selectGroup(item){
        props.closeSearchGroup(item)
    }

    const renderItem = ({ item }) => <Item group={item} name={item.name} selectGroup={selectGroup} />;
    
    return (
        <View style={{position:'fixed', top:0, left:0, width:'100%', height:'100%', flex:1, backgroundColor:'white', zIndex:3}}>
            <View style={{backgroundColor:'#fff', paddingLeft:20, paddingRight:20, borderBottomWidth:'thin', 
                    borderBottomColor:'#d0d0d0', width:'100%'}}>
                <View style={{height:57, justifyContent:'center', alignItems:'center'}}>
                <View style={{width:'100%', position:'absolute', top:0, left:0, zIndex:2, height:57, display:'flex', justifyContent:'center'}}>
                    <TouchableHighlight style={{onPress:'tran'}} onPress={() => {props.closeSearchGroup(null)}}>
                        <View>
                            <Text>Close</Text>
                        </View>
                    </TouchableHighlight>
                        
                    </View>
                    <Text>Select Or Create Group</Text>
                </View>
            </View>
            <View style={textStyles.container}>
                <TextInput
                    style={textStyles.field}
                    placeholder={'Search Group, min 3 characters'}
                    onChangeText={searchServerGroup}
                />
            </View>
            <FlatList 
                data={data} 
                renderItem={renderItem} 
                ListEmptyComponent={() => <Text style={{alignSelf:'center', marginTop:50}}>Search a group or create a new one.</Text>} 
                keyExtractor={item => `${item.id}`} />
            {searchText !== '' && (
                <View style={{position:'fixed', bottom:0, width:'100%'}}>
                    <TouchableHighlight  onPress={createGroup}>
                        <View style={[buttonStyle.container]}>
                            <Text style={[buttonStyle.text]}>Create New {searchText} Group</Text>
                        </View>
                    </TouchableHighlight>
                </View>
            )}
        </View>
    )
}

const textStyles = StyleSheet.create({
    container: {
        padding:10,
        position:'relative'
    },
    field: {
        height: 50, 
        borderColor: '#0f1c2d', 
        color:'#0f1c2d',
        borderWidth: 2, 
        width:'100%', 
        padding:5, 
        outlineColor: '#f7a81b',
        fontFamily: 'OpenSans_400Regular',
    }
  });

  const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold',
        paddingLeft:10,
        paddingRight:10
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

const styles = StyleSheet.create({
    item: {
      backgroundColor: 'white',
      padding: 20,
      marginVertical: 8,
      marginHorizontal: 16,
      fontFamily:'OpenSans_400Regular',
      borderBottomWidth:1,
      borderBottomColor:'#0f1c2d'
    },
    title: {
      fontSize: 32,
    },
  });