import api from "./api";

export default {
    login:(obj) => {
        return api.post(`v1/auth/login`, obj)
    },
    register:(obj) => {
        return api.post(`v1/auth/register`, obj)
    },
    pre_register:(obj) => {
        return api.post(`v1/auth/pre_register`, obj)
    },
    me:() => {
        return api.get(`v1/auth/me`)
    },
    resetPassword:(obj) => {
        return api.post(`v1/auth/recovery-password`, obj)
    },
    nicknameRecovery:(obj) => {
        return api.post(`v1/auth/nickname-recovery`, obj)
    },
    checkResetPasswordToken:(token) => {
        return api.get(`v1/auth/reset-password/${token}`)
    },
    setNewPassword:(obj) => {
        return api.post(`v1/auth/new-password`, obj)
    },
    logout:() => {
        return api.post(`v1/auth/logout`)
    },
    accounts:() => {
        return api.get(`v1/auth/accounts`)
    },
    selectAccount:(id) => {
        return api.post(`v1/auth/select-account`, {account_id: id})
    },
    exist_user:(obj) => {
        return api.post(`v1/auth/exist_user`, obj)
    }
}