export default({
    appendParameters(search) {
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                if(element !== null && element !== undefined && element !== '')
                    _search.push(`${key}=${element}`)
            }
        }
        _search = _search.join('&')
        return _search;
    }
})