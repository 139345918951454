import api from "./api";

export default {
    check_qr_code:(qrCode) => {
        return api.get(`v1/user/check-qr-code/${qrCode}`)
    },
    new_task_data:(obj) => {
        return api.post(`v1/user/new-task-data`, obj)
    },
    get_grades:() => {
        return api.get(`v1/grades`)
    },
    get_schools:() => {
        return api.get(`v1/schools`)
    },
    get_version:() => {
        return api.get(`v1/version`)
    },
}