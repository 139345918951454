import React, {useContext} from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, TouchableHighlight } from 'react-native';
import { AppLoading } from 'expo';
import { useFonts, OpenSans_400Regular, OpenSans_700Bold, OpenSans_800ExtraBold } from '@expo-google-fonts/open-sans';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Header from '../components/Header';
import AuthApi from '../api/authentication';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { LoadingContext } from '../components/LoadingContext';

export default function NicknameRecovery(props) {
    let [fontsLoaded] = useFonts({
        OpenSans_400Regular,
        OpenSans_700Bold,
        OpenSans_800ExtraBold
    });

    const {globalLoading, setGlobalLoading} = useContext(LoadingContext)

    async function nicknameRecovery(values, resetForm) {
        setGlobalLoading(true)
        const resp = await AuthApi.nicknameRecovery(values).catch(() => {})
        if(resp){
            toastr.success(resp.data.message)
            resetForm()
        }
        setGlobalLoading(false)
    }

    if (!fontsLoaded) {
        return <AppLoading />;
    }

    return (

        <React.Fragment>
            <Header {...props} title="Password Recovery" />
            <ScrollView contentContainerStyle={{flexGrow: 1}}> 
                <View style={styles.container}>
                    <Text style={{fontFamily:'OpenSans_800ExtraBold', color:'#0f1c2d', fontSize:40, textAlign:'center'}}>Nickname recovery</Text>
                    
                    <Formik
                        initialValues={{ 
                            email:''
                        }}
                        onSubmit={(values, actions) => {
                            nicknameRecovery(values, actions.resetForm)
                        }}
                        
                        validationSchema={validationSchema}
                        >
                        {({values, submitForm, setFieldValue, isValid, dirty, errors}) => (

                        <View style={{maxWidth:'80%', width:'100%', marginTop:100}}>
                            <View style={textStyles.container}>
                                <TextInput                                
                                    style={textStyles.field}
                                    placeholder={'E-mail'}
                                    onChangeText={text => setFieldValue('email', text)}
                                    value={values.email}
                                />
                                    <Text style={{ color: 'red', position:'absolute', fontWeight:'bold', bottom:-17, fontSize:12 }}>{errors.email}</Text>
                            </View>
                            
                            
                            <TouchableHighlight disabled={!(isValid && dirty)} onPress={() => submitForm()}>
                                <View style={[buttonStyle.container, {opacity:!(isValid && dirty) ? '0.5' : 1}]}>
                                    <Text style={buttonStyle.text}>Send e-mail</Text>
                                </View>
                            </TouchableHighlight>
                        </View>
                    )}
                    </Formik>

                </View>
            </ScrollView>
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const textStyles = StyleSheet.create({
  container: {
    marginBottom:30,
    position:'relative'
  },
  field: {
    height: 50, 
    borderColor: '#0f1c2d', 
    color:'#0f1c2d',
    borderWidth: 2, 
    width:'100%', 
    padding:5, 
    outlineColor: '#f7a81b',
    fontFamily: 'OpenSans_400Regular',
  }
});

const buttonStyle = StyleSheet.create({
    container:{
        width:'100%',
        height:50,
        backgroundColor:'#0f1c2d',
        justifyContent:'center',
        textAlign:'center',
        color:'white',
        alignContent:'center',
        fontFamily:'OpenSans_700Bold'
    },
    text: {
        color:'white',
        fontFamily:'OpenSans_700Bold',
        textTransform:'uppercase'
    }
})

const validationSchema = Yup.object().shape({
    email: Yup.string()
      .label('Email')
      .email()
      .required(),
  })